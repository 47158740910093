/**
 * keuzehulp.js
 */

var locked = false;
var loadingpage = false;
var typingTimer;                //timer identifier
var doneTypingInterval = 750;  //time in ms, 5 seconds for example
// $(window).on("load", function () {
// 	$(".keuzehulp__mainbar__title").html('Je zocht op ');
// 	$(".keuzehulp__mainbar__title__variable").attr("style", "display: block");
// });
function sendform(selector = "#form-keuzehulp") {
  $('button[type="submit"].cf-button--blue-alt').prop(
    "disabled",
    true
  );
  $('button[type="submit"].cf-button--blue-alt').addClass(
    "loading"
  );
  $(".training__sidebar-other__loading-spinner").show();
  console.log(loadingpage+"<<");
  console.log(selector+' sendingform '+$(selector).attr("action") );
  $.post($(selector).attr("action"), $(selector).serialize())
    .done(function (data) {
      //alert(data);

      $(".cf-button--blue-alt").prop("disabled", false);
      var grid = $(data)
        .find(".categorie__overview__filter__grid")
        .contents();
      if(loadingpage === true){
        $(".categorie__overview__filter__grid").append(grid);
      }else{
        $(".categorie__overview__filter__grid").html(grid);
      }
      var list = $(data)
        .find(".categorie__overview__filter__list")
        .contents();

      if(loadingpage === true){
        $(".categorie__overview__filter__list").append(list);
      }else{
        $(".categorie__overview__filter__list").html(list);

      }
      var barebonelist = $(data)
        .find(".categorie__overview__filter__barebonelist")
        .contents();

      if(loadingpage === true){
        $(".categorie__overview__filter__barebonelist").append(barebonelist);
      }else{
        $(".categorie__overview__filter__barebonelist").html(barebonelist);
      }
      var aanbod = $(data)
        .find(".keuzehulp__mainbar__content__menu__option.alles")
        .contents();
      
      if(locked == false){
      $(".keuzehulp__mainbar__content__menu__option.alles").html(aanbod);
      }
      var resultstext = aanbod.text();
      var regExp = /\(([^)]+)\)/;
      var matches = regExp.exec(resultstext);
      $('button[type="submit"].cf-button--blue-alt > .cf-button__text').html(
        "Toon resultaten (" + matches[1] + ")"
      );
      //console.log(matches[1]+"<>");
      $("#resultcount").html(matches[1]);

      //var counter = $(data)
      //  .find(".keuzehulp__maincontent__resultcount__count")
      //  .contents();
      //$(".keuzehulp__maincontent__resultcount__count").html(counter);
      //$(".keuzehulp__maincontent__resultcount").css('display','inline-block');
      
      if(locked == false){
      var cursussen = $(data)
        .find(".keuzehulp__mainbar__content__menu__option.training")
        .contents();
      $(".keuzehulp__mainbar__content__menu__option.training").html(
        cursussen
      );
      var opleidingen = $(data)
        .find(".keuzehulp__mainbar__content__menu__option.opleiding")
        .contents();
      $(".keuzehulp__mainbar__content__menu__option.opleiding").html(
        opleidingen
      );
      var lastminutes = $(data)
        .find(".keuzehulp__mainbar__content__menu__option.lastminute")
        .contents();
      $(".keuzehulp__mainbar__content__menu__option.lastminute").html(
        lastminutes
      );

      }
      var lastPage = $(data)
        .find('input[name="lastpage"]')
        .val();
      $('input[name="lastpage"]').val(lastPage);
      $('button[type="submit"].cf-button--blue-alt').removeClass(
        "loading"
      );
      $(".training__sidebar-other__loading-spinner").hide();
      if(loadingpage === false){
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
      loadingpage = false;
      locked = false;
    })
    .fail(function () {
      //alert( "error" );
    });
}

$(function () {
  $('.checkbox-container').on('click',function(){
    console.log($(this).find('input[type="checkbox"]').prop( "checked"));
    if($(this).find('input[type="checkbox"]').prop( "checked")){
      $(this).find('input[type="checkbox"]').prop( "checked", false);
    }else{
      $(this).find('input[type="checkbox"]').prop( "checked", true);
    }
    loadingpage = false;
    $('input[name="page"]').val("1");
    if(window.innerWidth > 1024){
      sendform(".keuzehulp__sidebar #form-keuzehulp");
    }else{
      sendform(".keuzehulp__offcanvas #form-keuzehulp");
    }
  });
  $(".keuzehulp-filter-button").on("click", function () {
    $(".keuzehulp-filter-button").hide();
    $(".keuzehulp-filters").attr("style", "display: block !important");
  });

  $(".keuzehulp-layout-button__icons").on("click", function () {
    if ($(this).hasClass("grid")) {
      $(".keuzehulp-layout-button__icons.grid").addClass("selected");
      $(".keuzehulp-layout-button__icons.list").removeClass("selected");
      $(".keuzehulp-layout-button__icons.barebonelist").removeClass("selected");

      $(".categorie__overview__filter__grid").show();
      $(".categorie__overview__filter__list").hide();
      $(".categorie__overview__filter__barebonelist").hide();
    } else if ($(this).hasClass("list")) {
      $(".keuzehulp-layout-button__icons.grid").removeClass("selected");
      $(".keuzehulp-layout-button__icons.list").addClass("selected");
      $(".keuzehulp-layout-button__icons.barebonelist").removeClass("selected");

      $(".categorie__overview__filter__grid").hide();
      $(".categorie__overview__filter__list").show();
      $(".categorie__overview__filter__barebonelist").hide();
    } else if ($(this).hasClass("barebonelist")) {
      $(".keuzehulp-layout-button__icons.grid").removeClass("selected");
      $(".keuzehulp-layout-button__icons.list").removeClass("selected");
      $(".keuzehulp-layout-button__icons.barebonelist").addClass("selected");

      $(".categorie__overview__filter__grid").hide();
      $(".categorie__overview__filter__list").hide();
      $(".categorie__overview__filter__barebonelist").show();
    }
  });
  // keuzehulp new
  $(".keuzehulp__mainbar__content__menu__option__button__icons").on(
    "click",
    function () {
      if ($(this).hasClass("grid")) {
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.grid"
        ).addClass("selected");
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.list"
        ).removeClass("selected");
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.barebonelist"
        ).removeClass("selected");

        $(".categorie__overview__filter__grid").show();
        $(".categorie__overview__filter__list").hide();
        $(".categorie__overview__filter__barebonelist").hide();
      } else if ($(this).hasClass("list")) {
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.grid"
        ).removeClass("selected");
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.list"
        ).addClass("selected");
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.barebonelist"
        ).removeClass("selected");

        $(".categorie__overview__filter__grid").hide();
        $(".categorie__overview__filter__list").show();
        $(".categorie__overview__filter__barebonelist").hide();
      } else if ($(this).hasClass("barebonelist")) {
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.grid"
        ).removeClass("selected");
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.list"
        ).removeClass("selected");
        $(
          ".keuzehulp__mainbar__content__menu__option__button__icons.barebonelist"
        ).addClass("selected");

        $(".categorie__overview__filter__grid").hide();
        $(".categorie__overview__filter__list").hide();
        $(".categorie__overview__filter__barebonelist").show();
      }
    }
  );

  //Default to list layout
  if ($(window).width() < 641) {
    if ($(".keuzehulp-layout-button__icons").length) {
      $(".keuzehulp-layout-button__icons.list").addClass("selected");
      $(".categorie__overview__filter__list").show();

      $(".keuzehulp-layout-button__icons.grid").removeClass("selected");
      $(".categorie__overview__filter__grid").hide();
    }
  }
  //Default to list layout keuzehulp new
  if ($(window).width() < 641) {
    if ($(".keuzehulp__mainbar__content__menu__option__button__icons").length) {
      $(
        ".keuzehulp__mainbar__content__menu__option__button__icons.list"
      ).addClass("selected");
      $(".categorie__overview__filter__list").show();

      $(
        ".keuzehulp__mainbar__content__menu__option__button__icons.grid"
      ).removeClass("selected");
      $(".categorie__overview__filter__grid").hide();
    }
  }
  $(".form-keuzehulp .vakgebied").on("change", function () {
    //Send value to JSON endpoint
    var vakgebied = this.value;
    var url = "/trainingen/getsubcategories";
    $.post(url, { vakgebied: vakgebied })
      .done(function (data) {
        var items = "";
        items += '<option value="0">Kies je onderwerp</option>';
        $.each(data, function (i, item) {
          items +=
            '<option value="' + item.id + '">' + item.title + "</option>";
        });
        $(".form-keuzehulp .onderwerpen").html(items);
      })
      .fail(function () {
        //alert( "error" );
      });
  });

  $(".keuzehulp__sidebar #form-keuzehulp, .keuzehulp__offcanvas #form-keuzehulp").on("submit", function (e) {
    e.preventDefault();
    hidefilter();
  });
  $("#search-keuzehulp-mobile, #search-keuzehulp-desktop").on("submit", function (e) {
    e.preventDefault();
  });

  function hidefilter() {
    $('#keuzehulpOffcanvas').foundation('close');
    //if ($(window).width() < 641) {
      //$(".keuzehulp-filter-button").show();
      //$(".keuzehulp-filters").attr("style", "display: none");
    //}
  }
  function doSearch () {
    //do something
    var searchquery = $(".search-keuzehulp input").val();
    loadingpage = false;
    locked = false;
    $('input[name="page"]').val("1");
    if(searchquery.length > 2){
      $(".search-keuzehulp input").val(searchquery);
      if(window.innerWidth > 1024){
        sendform("#search-keuzehulp-desktop");
      }else{
        sendform("#search-keuzehulp-mobile");
      }
      if (searchquery == "") {
        $(".keuzehulp__mainbar__title").text("Alle trainingen en opleidingen");
        $(".keuzehulp__maincontent__resultcount").hide();
      } else {
        $(".keuzehulp__mainbar__title").text("Je zocht naar '" + searchquery + "'");
        $(".keuzehulp__maincontent__resultcount__term").text(
          "voor '" + searchquery + "'"
        );
        $(".keuzehulp__maincontent__resultcount").css('display','inline-block');
      }
    }else{
        if(window.innerWidth > 1024){
          sendform("#search-keuzehulp-desktop");
        }else{
          sendform("#search-keuzehulp-mobile");
        }
        $(".keuzehulp__mainbar__title").text("Alle trainingen en opleidingen");
        $(".keuzehulp__maincontent__resultcount").hide();
    }
  }

  function doSomething(){
    console.log('test2sec');
  }

  $(".search-keuzehulp input").on("keyup", function () {
    var searchquery = $(this).val();
    $(".search-keuzehulp input").val(searchquery);
    clearTimeout(typingTimer);
    console.log('timeout');
    console.log(doneTypingInterval);
    //typingTimer = setTimeout(doSomething, doneTypingInterval);
    typingTimer = setTimeout(doSearch, doneTypingInterval);
  });

  $(".search-keuzehulp input").on("keydown", function () {
    console.log('cleartimeout');
    clearTimeout(typingTimer);
  });

  $(".form-keuzehulp select, .form-keuzehulp input").on("change", function () {
    if ($(this).hasClass("vakgebied")){
      $(".onderwerpen").val(0);
      var vakgebied = $(this).val();
      $(".vakgebied").val(vakgebied);

    } 
    if ($(this).hasClass("onderwerpen")){
      var onderwerpen = $(this).val();
      $(".onderwerpen").val(onderwerpen);

    }
    if ($(this).hasClass("duur")){
      var duur = $(this).val();
      $(".duur").val(duur);

    }
    if ($(this).hasClass("startdatum")){
      var startdatum = $(this).val();
      $(".startdatum").val(startdatum);

    }
    if ($(this).hasClass("vorm")){
      var vorm = $(this).val();
      $(".vorm").val(vorm);

    }
    if ($(this).hasClass("budget")){
      var budget = $(this).val();
      $(".budget").val(budget);

    }
    loadingpage = false;
    $('input[name="page"]').val("1");
    if(window.innerWidth > 1024){
      sendform(".keuzehulp__sidebar #form-keuzehulp");
    }else{
      sendform(".keuzehulp__offcanvas #form-keuzehulp");
    }
  });
  // test wis filter
  $(".form-keuzehulp select, .form-keuzehulp input").on("change", function () {
	  $(".keuzehulp__sidebar__container__clear").attr("style", "display: flex");
  });

  $("#reset_keuzehulp").on("click", function () {
    $(".vakgebied").val(0);
    $(".onderwerpen").val(0);
    $(".duur").val(0);
    $(".startdatum").val(0);
    $(".vorm").val(0);
    //  $(".vorm").prop("checked", false);
    //  $(".budget").prop("checked", false);
    $(".budget").val(0);
    $(".korting").val(0);
    loadingpage = false;
    $('input[name="page"]').val("1");
    sendform();
	($(this).attr("style", "display: none"));
  });
});
/*var observer = new IntersectionObserver(function(entries) {
	// isIntersecting is true when element and viewport are overlapping
	// isIntersecting is false when element and viewport don't overlap
	if(entries[0].isIntersecting === true)
    console.log('We are intersecting');
    if(locked === false){
      locked = true;
      console.log('Element has just become visible in screen');
      loadingpage = true;
      var page = document.querySelector("#currentPage");
      pageNumber = parseInt(page.value);

      var lastpage = document.querySelector("#lastPage").value;
      lastpage = parseInt(lastpage);

      if(pageNumber < lastpage){

        pageNumber = pageNumber + 1;
        console.log(pageNumber);
        $('input[name="page"]').val(pageNumber);
        if(window.innerWidth > 1024){
          sendform(".keuzehulp__sidebar #form-keuzehulp");
        }else{
          sendform(".keuzehulp__offcanvas #form-keuzehulp");
        }
      }
    }
}, { root: null, rootMargin: "-500px", threshold: 0 });
var trigger = document.getElementById("infiniteScrollTrigger");
if(trigger !== null){
observer.observe(document.querySelector("#infiniteScrollTrigger"));
}else{
  console.log('element not found');
} */

function checkIfElementIsNearViewport() {
  var trigger = document.getElementById("infiniteScrollTrigger");

  if (!trigger) {
      console.log('Element not found');
      return;
  }

  var rect = trigger.getBoundingClientRect();
  var offset = 1500; // Adjust how early it triggers (before visible)

  if (rect.top <= window.innerHeight + offset) { 
      console.log('Element is near viewport, triggering action');

      if (!locked) {
          locked = true;
          console.log('Element has just become visible in screen');
          loadingpage = true;

          var page = document.querySelector("#currentPage");
          pageNumber = parseInt(page.value);
          var lastpage = parseInt(document.querySelector("#lastPage").value);

          if (pageNumber < lastpage) {
              pageNumber += 1;
              console.log(pageNumber);
              $('input[name="page"]').val(pageNumber);

              if (window.innerWidth > 1024) {
                  sendform(".keuzehulp__sidebar #form-keuzehulp");
              } else {
                  sendform(".keuzehulp__offcanvas #form-keuzehulp");
              }
          }
      }
  }
}

// Listen for scrolling
window.addEventListener("scroll", checkIfElementIsNearViewport);

// Also run once in case the element is already visible on page load
document.addEventListener("DOMContentLoaded", checkIfElementIsNearViewport);

